import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Head from '../components/head'

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      <h2>Innovation. Experience. Integrity.</h2>
      <p>Creating digital business processes the way business needs them. Founded on experience and passion for your success.
      </p>
      <StaticImage src='../../static/handshake.jpg' alt="Handshake" height={500} />
      <h4>Additional services</h4>
      <ul>
        <li><strong>Business Process</strong> analysis, modelling, development, performance optimization</li>
        <li><strong>Process</strong> and <strong>task</strong> mining</li>
        <li><strong>Netgrif Application Platform</strong> consulting and development</li>
        <li><strong>Salesforce</strong> consulting and development</li>
        <li><strong>Independent</strong> Technology and IT Services Vendor asessment and evaluation</li>
        <li><strong>IT Infrastructure optimization</strong> - servers (special focus on IBM System z, IBM Power systems), enterprise storage and backup solutions, applications and hybrid cloud deployments</li>
        <li>Server and storage <strong>performance</strong> optimization and improvement, capacity planning and optimization</li>
      </ul>
    </Layout>
  )
}

export default IndexPage